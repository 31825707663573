import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ['input']
  static values = { reflex: String }

  fieldChanged() {
    if (this.reflexValue === "") {
      return
    }

    const input = {}

    this.inputTargets.forEach(target => {
      input[target.name] = target.value
    })

    this.stimulate(this.reflexValue, input)
  }
}
