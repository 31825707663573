import { application } from "./application"

import consumer from '../channels/consumer'

import controllers from "./**/*_controller.js"
import StimulusReflex from "stimulus_reflex"
import CableReady from 'cable_ready'

CableReady.initialize({ consumer })


controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

StimulusReflex.debug = true
